export const FirebaseConfig =  {
  apiKey: "AIzaSyABWjZUsrulWFnIqRdtaFBsygD-2joO22c",
  authDomain: "cirklecab.firebaseapp.com",
  databaseURL: "https://cirklecab.firebaseio.com",
  projectId: "cirklecab",
  storageBucket: "cirklecab.appspot.com",
  messagingSenderId: "774114022287",
  appId: "1:774114022287:web:668fe4a1ce1d8da02731fa",
  measurementId: "G-KYVD66KLVJ",
  appName: "CirkleCab"
};
